<template>
  <div class="calendar-container">
      <div class="week">
          <div v-for="(weekDay, index)  in weekDays" :key="'weekDay' + index" class="weekday">
              {{weekDay}}
          </div>
          <div style="clear: left;"></div>
      </div>   
    <div v-for="month in months" :key="month.id" class="month">
        <div class="title">
            {{ parseMonthToString(month.month) }} {{ month.year }}
        </div>
      <div class="days">
        <div
          v-for="day in month.days"
          :key="day.id"
          class="day"
          :date="day.date"
          @click="setDate($event, day.date, '8:00')"
        >
          <span>{{ day.day }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Calendar',
  computed: {
      ...mapGetters({
          defaultTimeout: 'config/defaultTimeout',
          timeSelectPossible: 'selectedServices/timeSelectPossible',
      }),
  },
  data() {
    return {
      activeDay: false,
      dateToday: this.parseDate(new Date()),
      monthsLimit: 4, // Max months (including current one)
      months: undefined,
      weekDays: ['M', 'T', 'O', 'T', 'F', 'L', 'S'],
      availableDates: []
    };
   },
  created: function() {
      this.months = this.populateMonths();
  },
  watch: {
      months() {
          const data = {
              'WorkshopId': this.$attrs.workshopId,
              'Services': this.$attrs.selectedServices,
              'Days': '90',
          };
          this.fetchDates(data);
    },
  },
  methods: {
      availableDatesActiveClass() {

          const days = document.querySelectorAll('.day');
          for (let i = 0; i < days.length; i++) {
              const day = days[i];
              if (this.availableDates.some(e => e.availableDate === day.getAttribute('date')))
                  day.classList.add('available');
          }
      },
    populateMonths() {
      let months = [];
      const date = new Date();
      const monthCurrent = date.getMonth();
      const yearCurrent = date.getFullYear();
      months.push({
        month: monthCurrent,
        year: yearCurrent,
        days: this.populateDays(monthCurrent, yearCurrent)
      });

      let count = 1;
      while (count < this.monthsLimit) {
        let month = monthCurrent + count;
        let year = yearCurrent;

        // Next year
        if (month > 11) {
          month -= 12;
          year = yearCurrent + 1;
        }

        months.push({
          month: month,
          year: year,
          days: this.populateDays(month, year)
        });

        count++;
      }

      return months;
    },
    populateDays(month, year) {
      let days = [];
      let dayFirstOfMonth = new Date(year, month, 1).getDay();
      const dayLastOfMonth = new Date(year, month + 1, 0);

      // If first day of month is on a sunday, populate 6 empty days
      if (dayFirstOfMonth === 0) {
        dayFirstOfMonth = 7;
      }

      // Get days before first day of month in same week
      for (let i = 1; i < dayFirstOfMonth; i++) {
        days.push({
          day: ''
        });
      }

      // Populate days with numbers in month
      let count = 1;
      while (count <= dayLastOfMonth.getDate()) {
        days.push({
          day: count,
          date: this.parseDate(new Date(year, month, count))
        });
        count++;
      }

      return days;
    },
    parseDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      return year + '-' + month + '-' + day;
    },
    parseMonthToString(month) {
      const months = [
        'januari',
        'februari',
        'mars',
        'april',
        'maj',
        'juni',
        'juli',
        'augusti',
        'september',
        'oktober',
        'november',
        'december'
      ];
      return months[month];
    },
    setDate(event, date, time) {
      if (event.target.classList.contains('available')) {
        this.scrollToDate(event);

        const selected = document.querySelectorAll('.calendar-container .selected');
        if (selected.length) {
            selected.forEach(element => element.classList.remove('selected'));
        }

          if (this.timeSelectPossible && this.$attrs.wsHasSelectableTimes) {
              time = null;
          }

        event.target.classList.add('selected');
        const matchedDate = this.availableDates.find(e => e.availableDate === date);
        this.$emit('selectDate', date, time, this.getSlots(matchedDate));
      }
    },
    getSlots(availableItem) {
        let availableSlots = [];

        const openFrom = availableItem.openFrom.padStart(5, '0');
        const openTo = availableItem.openTo.padStart(5, '0');

        let current = new Date(`${availableItem.availableDate}T${openFrom}:00`);
        const end = new Date(`${availableItem.availableDate}T${openTo}:00`);

        while (current < end) {
            const hours = String(current.getHours()).padStart(2, '0');
            const minutes = String(current.getMinutes()).padStart(2, '0');
            availableSlots.push(`${hours}:${minutes}`);
            current.setMinutes(current.getMinutes() + 30);
        }
        return availableSlots;
    },
    scrollToDate(event) {
      const contentContainer = document.querySelector(
        '.offer__calendar__container'
      );
      const selectedDate = event.target;

      contentContainer.scrollTop = selectedDate.offsetTop - 180;
      },
      async fetchDates(data) {
          await axios
              .post(`/api/applications/offers/v2/getTimeslots`, data)
              .then(x => {
                this.availableDates = x.data;
                this.availableDatesActiveClass();
              })
              .catch(error => {
                  // eslint-disable-next-line no-console
                  console.error(error);
              });
      }
  }
};
</script>
<style lang="scss">
    .week {
        border-bottom: 1px solid #EBEDEE;
        margin-bottom: 16px;

        .weekday {
            box-sizing: border-box;
            color: #cacbc7;
            font-size: 16px;
            float: left;
            margin: 12px 0;
            position: relative;
            text-align: center;
            transition: all 0.25s ease-in-out;
            font-weight: 600;
            color: #101820;
            width: 14.286%;
        }
    }

.calendar-container {
  padding: 0 6px;
  .month {
    margin-bottom: 16px;
    overflow: hidden;
  }

  .days {
    margin: 0 -15px;
  }

  .day {
    cursor: pointer;
    box-sizing: border-box;
    color: #cacbc7;
    font-size: 16px;
    float: left;
    margin: 12px 0;
    position: relative;
    text-align: center;
    transition: all 0.25s ease-in-out;
    // 100% / 7
    width: 14.286%;

    &:before {
      content: '';
      border-radius: 100%;
      position: absolute;
      height: 40px;
      width: 40px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(0.5, 0.5, 0.5);
      visibility: hidden;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
    }

    &.available {
      font-weight: 600;
      color: #101820;
    }

    &.selected {
      color: #ffffff;

      &:before {
        background: #33c65a;
        visibility: visible;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
      }
    }

    span {
      display: inline-block;
      pointer-events: none;
      position: relative;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}
</style>
