<template>

    <div class="offer__price-display__fold-out" style="align-items:normal">

        <div v-if="open && price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null">

            <div v-if="price.servicePriceState.data.priceInfo.additionalServices.length"
                 class="offer__price-display__section-header">Service</div>
            <ul style="margin-bottom: 12px">
                <li v-for="(service, index) in price.servicePriceState.data.priceInfo.additionalServices"
                    v-bind:key="'services-' + index">
                    {{ service.name }}
                </li>
            </ul>

            <div class="offer__price-display__section-header">Vad ingår?</div>
            <ul style="margin-bottom: 12px">
                <li v-for="(article, index) in price.servicePriceState.data.priceInfo.articles"
                    v-bind:key="'articles-' + index">
                    {{ article.quantity }} st {{ article.name }} <span style="display:none">{{ article.requiredAmount }}</span> <!-- for debug -->
                </li>
            </ul>

        </div>

        <div v-if="(open && price.servicePriceState.data.simplePriceInfos.length > 0)">

            <div v-for="(sItem, index) in price.servicePriceState.data.simplePriceInfos" v-bind:key="'sItem-' + index">
                <div class="offer__price-display__section-header">Tjänst</div>
                <ul style="margin-bottom: 12px">
                    <li>
                        {{ sItem.serviceName }}
                    </li>
                </ul>

                <div class="offer__price-display__section-header">Vad ingår?</div>
                <ul style="margin-bottom: 12px">
                    <li v-for="(article, index2) in sItem.articles"
                        v-bind:key="'sItem-articles-' + index2">
                        {{ article.name }}
                    </li>
                </ul>
            </div>

        </div>

        <div class="offer__price-display__section offer__price-display__section--cost pd-summary" :class="{'pd-summary-showheadandbreaks' : showHeaderAndBreaks}">

            <template v-if="price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null">
                <div class="pd-item pd-head">
                    <span class="pd-text">Service</span>
                </div>

                <div class="pd-item">
                    <span class="pd-text">{{ texts.timeslot.workcost }}:</span>
                    <span class="pd-price">{{ price.servicePriceState.data.priceInfo.workAndDisposablesCostStringVat }} kr</span>
                </div>
                <div v-if="!price.servicePriceState.data.priceInfo.isDiscount" class="pd-item pd-break">
                    <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                    <span class="pd-price">{{ price.servicePriceState.data.priceInfo.articleCostStringVat }} kr</span>
                </div>
                <div v-if="price.servicePriceState.data.priceInfo.isDiscount" class="pd-item">
                    <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                    <span class="pd-price">
                        <span class="price-before">{{ price.servicePriceState.data.priceInfo.articleCostStringVat }} kr</span>
                        <span class="price-after">{{ price.servicePriceState.data.priceInfo.articleCostStringVatWithDiscount }} kr</span>
                    </span>
                </div>
                <div v-if="price.servicePriceState.data.priceInfo.isDiscount" class="pd-item pd-break" style="padding:0;">
                    <span class="pd-text"></span>
                    <span class="pd-price pd-info"><i class="fal fa-tag"></i> {{ price.servicePriceState.data.priceInfo.discountDescription }} ({{price.servicePriceState.data.priceInfo.articleCostStringVatDiscount}} kr)</span>
                </div>

            </template>

            <template v-if="price.servicePriceState.data !=null">
                <div v-for="(service, index) in price.servicePriceState.data.simplePriceInfos"
                     v-bind:key="'simpleprices-' + index" style="display:contents">
                    <div class="pd-item pd-head">
                        <span class="pd-text">{{service.serviceName}}</span>
                    </div>
                    <div class="pd-item">
                        <span class="pd-text">{{ texts.timeslot.workcost }}:</span>
                        <span class="pd-price">{{ service.workAndDisposablesCostStringVat }} kr</span>
                    </div>
                    <div v-if="!service.isDiscount" class="pd-item pd-break">
                        <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                        <span class="pd-price">{{ service.articleCostStringVat }} kr</span>
                    </div>
                    <div v-if="service.isDiscount" class="pd-item">
                        <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                        <span class="pd-price">
                            <span class="price-before">{{ service.articleCostStringVat }} kr</span>
                            <span class="price-after">{{ service.articleWithDiscountCostString }} kr</span>
                        </span>
                    </div>
                    <div v-if="service.isDiscount" class="pd-item pd-break">
                        <span class="pd-text"></span>
                        <span class="pd-price pd-info"><i class="fal fa-tag"></i> {{ service.discountDescription }} ({{service.articleDiscountString}} kr)</span>
                    </div>
                </div>
            </template>

            <div v-for="(service, index) in price.servicePrices"
                 v-bind:key="'servicesprices-' + index" class="pd-item pd-break">
                <span class="pd-text">{{ service.name }}:</span>
                <span class="pd-price">{{ service.staticPrice }} kr</span>
            </div>
            <div class="pd-item">
                <span class="pd-text pd-total">Totalsumma:</span>
                <span class="pd-price pd-price-total">{{ formatPrice(totalPrice) }}</span>
            </div>
            <div class="pd-item pd-last">
                <span class="pd-text"></span>
                <span class="pd-vat pd-info">inkl. moms</span>
            </div>
            <div class="pd-item pd-last">
                <span class="pd-text"></span>
                <span class="pd-info" v-if="partPayment.fee > 0">
                    <i class="fa fa-info-circle circle1 blue" v-on:mouseover="doToolTip1()" v-on:mouseleave="hideToolTips()"></i>
                    <label class="tooltip-partpay1" v-bind:style="toolTip1Active ? 'display: block' : ''">
                        {{texts.footerPartPaymentToolTip}}
                        <label class="tooltip-arrow">&nbsp;</label>
                    </label>
                    Delbetala från <br /><i class="part-payment-price">{{partPayment.fee}} kr/mån</i> i {{partPayment.feeMonths}} mån
                    <i class="fa fa-info-circle circle2 blue" v-on:mouseover="doToolTip2()" v-on:mouseleave="hideToolTips()"></i>
                    <label class="tooltip-partpay2" v-bind:style="toolTip2Active ? 'display: block' : ''">
                        {{texts.footerPartPaymentToolTip}}
                        <label class="tooltip-arrow">&nbsp;</label>
                    </label>
                </span>
            </div>
            <div v-if="price.servicePriceState.data != null" class="pd-item pd-last">
                <a v-if="!open" href="#" class="link-btn" v-on:click="openDetails">{{texts.carform.showParts}}</a>
                <a v-if="open" href="#" class="link-btn" v-on:click="closeDetails">Stäng</a>
            </div>
        </div>

    </div>

</template>

<script>

    import { mapState } from 'vuex';
    import { formatPrice } from '../utils/formatPrice';

    export default {
        name: 'priceDetailsConfirm',
        data() {
            return {
                open: false,
                formatPrice,
                toolTip1Active: false,
                toolTip2Active: false,
            };
        },
        computed: {
            ...mapState({
                price: state => state.price,
                priceDate: state => state.price.servicePriceState.data,
                texts: state => state.config.texts,
                totalPrice: state => state.price.totalPrice,
                partPayment: state => state.price.partPayment,
            }),
            showHeaderAndBreaks: {
                get() {
                    return this.price.serviceCount > 1;
                }
            },
        },
        methods: {
            openDetails() {
                this.open = true;
            },
            closeDetails() {
                this.open = false;
            },
            doToolTip1() {
                this.toolTip1Active = true;
            },
            doToolTip2() {
                this.toolTip2Active = true;
            },
            hideToolTips() {
                this.toolTip1Active = false;
                this.toolTip2Active = false;
            },
        },
    }

</script>