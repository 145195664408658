<template>

    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid" :hideScroll="showCustomDatePicker" :hasBackBtn="true" :enableHeaderOverlay="showCustomDatePicker" :title="'Välj verkstad'" @onNextStep="gotoNextStep" @onPrevStep="gotoPrevStep">

            <div class="offer__modal-container offer__workshop">

                <div class="offer__workshop__input" :class="{'hidden': preSelectedWorkhopId.length > 0}">
                    <i class="fal fa-search"></i>
                    <input id="workshop-query"
                           type="text"
                           required
                           minlength="4"
                           maxlength="100"
                           @keydown="queryDebounced"
                           placeholder="Sök på postnummer eller ort..."
                           v-model="query" />
                    <i class="far fa-location-arrow icon-blue" v-on:click="doPos" style="cursor: pointer;padding:6px"></i>
                </div>

                <div v-if="avaiableWorkshopHits.length" class="offer__workshop__results">
                    <div class="offer__workshop__sort">
                        <div v-if="hasDates && avaiableWorkshopHits.length > 1">
                            <a href="#" v-on:click="doSortByDate()" :class="sortByDate ? 'selected' : ''">Närmaste tid</a>
                            <a href="#" v-on:click="doSortByDistance()" :class="sortByDate ? '' : 'selected'">Närmaste avstånd</a>
                        </div>
                    </div>
                    <ul>
                        <li v-for="hit in avaiableWorkshopHits"
                            v-bind:key="hit.workshop.id"
                            class="offer__workshop__row" :class="{'offer__workshop__row--no-datepicker': hit.dates.length === 0}">
                            <div class="offer__workshop__sections">
                                <div class="offer__workshop__title">
                                    {{hit.workshop.title}}
                                </div>
                                <div class="offer__workshop__meta">
                                    {{` ${hit.workshop.address}, ${hit.workshop.city}`}}
                                </div>
                                <div v-if="hit.workshop.distance > 0" class="offer__workshop__distance">
                                    <i class="far fa-location-arrow"></i>
                                    {{hit.workshop.distance.toFixed(1)}} km
                                </div>
                            </div>
                            <div v-if="hit.workshop.disableOffer" class="offer__workshop__sections">
                            </div>
                            <div v-else class="offer__workshop__sections">
                                <div class="offer__workshop__time-headline" v-if="hit.dates.length">
                                    <template v-if="timeSelectPossible && hit.workshop.isTimeSelectable">
                                        Nästa lediga datum:
                                    </template>
                                    <template v-else>
                                        Nästa lediga tid:
                                    </template>
                                </div>
                                <div v-bind:class="{ offer__workshop__actions: hit.dates.length }">
                                    <div class="offer__workshop__date-container" v-if="hit.dates.length && timeSelectPossible && hit.workshop.isTimeSelectable">
                                        {{toDateString(hit.dates[0].date, '', 'long')}}
                                    </div>
                                    <div class="offer__workshop__select-container" v-if="hit.dates.length && (!timeSelectPossible || !hit.workshop.isTimeSelectable)"> 
                                        <select :id="'workshop-dates' + hit.workshop.id"
                                                @change="checkForDateModal($event, hit.workshop)">
                                            <template v-for="dateitem in hit.dates">
                                                <option v-if="checkSelectedDateList(hit.workshop.id, dateitem.date)"
                                                        v-bind:key="dateitem.date"
                                                        v-bind:value="dateitem.date"
                                                        v-bind:data-open="dateitem.open" selected>
                                                    {{toDateString(dateitem.date, dateitem.open, 'short')}}
                                                </option>
                                                <option v-else
                                                        v-bind:key="dateitem.date"
                                                        v-bind:value="dateitem.date"
                                                        v-bind:data-open="dateitem.open">
                                                    {{toDateString(dateitem.date, dateitem.open, 'short')}}
                                                </option>
                                            </template>
                                            <option v-if="checkSelectedDate(hit.workshop.id)" selected v-bind:value="selectedDate" v-bind:data-open="selectedTime">
                                                {{toDateString(selectedDate, selectedTime, 'short')}}
                                            </option>
                                            <option v-else value="">Välj datum längre fram i tiden</option>
                                        </select>
                                    </div>
                                    <div class="btn"
                                         @click="selectWorkshop(hit)"
                                         :class="{'btn--success': selectedAvailableWorkshop === hit.workshop.id}">
                                        <i v-if="selectedAvailableWorkshop === hit.workshop.id" class="fas fa-check btn__solo-icon"></i>
                                        {{ selectedAvailableWorkshop === hit.workshop.id ? '' : ((timeSelectPossible && hit.workshop.isTimeSelectable) ? 'Välj tid' : 'Välj') }}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <a href="#" v-on:click="loadNextWorkshops" v-if="avaiableWorkshopHits.length >= 10" class="ax-button ax-button-primary mt-12">Ladda in fler</a>
                </div>
                <div v-if="loading" class="offer__workshop__loader-container">
                    <img src="../assets/AX-Spinner-2.gif" class="offer__workshop__loader-image" />
                </div>
                <div class="offer__pop-up__overlay" v-if="showCustomDatePicker"></div>
                <div class="offer__calendar offer__pop-up" v-if="showCustomDatePicker">
                    <div class="offer__pop-up__close-tab" @click="closeCustomDatePicker">
                        Stäng
                        <i class="fal fa-chevron-down"></i>
                    </div>
                    <div class="offer__calendar__container">
                        <Calendar :workshopId="selectedAvailableWorkshop" :selectedServices="selectedServices" :wsHasSelectableTimes="wsHasSelectableTimes"
                                  @selectDate="setDate" />
                    </div>
                    <div class="offer__calendar__custom-date-container">
                        <div class="offer__calendar__custom-date-section">
                            <div class="offer__calendar__custom-date-label">
                                Datum:
                            </div>
                            <div v-if="pickerdate" class="offer__calendar__custom-date-success">
                                <i class="fal fa-check"></i>
                                {{ moment(pickerdate).format('DD MMMM') }}
                            </div>
                            <div v-else class="offer__calendar__custom-date-pending">
                                Välj ett datum
                            </div>
                        </div>
                        <div v-if="!timeSelectPossible || !wsHasSelectableTimes"  class="offer__calendar__custom-date-section">
                            <div class="offer__calendar__custom-date-label">
                                Tid:
                            </div>
                            <div v-if="pickertime" class="offer__calendar__custom-date-success">
                                <i class="fal fa-check"></i>
                                {{pickertime}}
                            </div>
                            <div v-if="!pickertime" class="offer__calendar__custom-date-pending">
                                Välj ett datum för att få en tid
                            </div>
                        </div>
                        <div v-else class="offer__calendar__custom-date-select">
                            <select @change="setTime" v-model="pickertime">
                                <option value="null">
                                    Välj tid
                                </option>
                                <option v-for="(slot, index) in pickerSlots" v-bind:key="'slotkey_' + index">
                                    {{slot}}
                                </option>
                            </select>
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>

    </div>

</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import steps from '../constants/steps';
    import states from "../constants/RequestStates";
    import ajaxHelper from '../store/utils/ajaxHelper';
    import localeDateString from '../store/utils/localeDateString';
    import ModalWrapper from './ModalWrapper.vue';
    import moment from 'moment';
    import debounce from 'lodash.debounce';
    import Calendar from './Calendar.vue';

    export default {
        components: { ModalWrapper, Calendar },
        name: 'workshopStep',
        data() {
            return {
                query: '',
                queryprev: '',
                geocoder: null,
                useDates: false,
                page: 1,
                lat: null,
                lng: null,
                avaiableWorkshopHits: [],
                showCustomDatePicker: false,
                pickerdate: null,
                pickertime: null,
                pickerSlots: [],
                moment,
                loading: false,
                selectedDateFlag: false,
                posDone: false,
                sortByDate: true,
                hasDates: false,
                wsHasSelectableTimes: false,
            };
        },
        created() {
            this.queryDebounced = debounce(this.queryKeydown.bind(this), 200);
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                workshopHits: state => state.workshop.pickWorkshopState.data,
                workshopHitsStatus: state => state.workshop.pickWorkshopState.status,
                selectedServices: state => state.selectedServices.ids,
                selectedWorkshopId: state => state.workshop.selectedWorkshopId,
                selectedWorkshop: state => state.workshop.selectedWorkshop.workshop,
                pickCarData: state => state.car.pickCarState.data,
                serviceType: state => state.car.selectedService,
                miles: state => state.car.miles,
                selectedServiceItems: state => state.selectedServices.items,
                preSelectedWorkhopServices: state => state.preselectedWorkshop.services.data,
                preSelectedWorkhopId: state => state.config.workshopId,
                selectedDate: state => state.price.selectedDate,
                selectedTime: state => state.price.selectedTime,
                priceApiVersion: state => state.price.priceApiVersion,
                allwaysShowDates: state => state.config.allwaysAllowBooking
            }),
            ...mapGetters({
                pricePossible: 'selectedServices/pricePossible',
                timeSelectPossible: 'selectedServices/timeSelectPossible',
                includesPriceApi: 'selectedServices/includesPriceApi',
                includesStaticPrice: 'selectedServices/includesStaticPrice',
            }),
            selectedAvailableWorkshop: {
                get() {
                    if (this.avaiableWorkshopHits != null &&
                        this.avaiableWorkshopHits.some(e => e.workshop.id == this.selectedWorkshopId))
                        return this.selectedWorkshopId;
                    return 0;
                },
                set(value) {
                    var selectedWorkshop = this.avaiableWorkshopHits.find(w => w.workshop.id == value);
                    this.$store.commit('workshop/updateSelectedWorkshopState', { workshopId: value, workshop: selectedWorkshop });
                },
            },
            selectedPriceApiVersion: {
                get() {
                    return this.priceApiVersion;
                },
                set(value) {
                    this.$store.commit('price/updateApiVersion', value);
                }
            },
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.workshopStep);
                },
            },
            stepValid: {
                get() {
                    if ((this.pickerdate == null || this.pickertime == null) && this.showCustomDatePicker)
                        return false;
                    return this.selectedAvailableWorkshop > 0;
                }
            }
        },
        watch: {
            selectedServices() {
                this.reset();
                this.query = '';
                this.queryprev = '';
                this.geocoder = null;
                this.page = 1;
                this.avaiableWorkshopHits = [];
            },
            workshopHits(newItems) {
                this.hasDates = false;
                if (newItems != null) {
                    this.avaiableWorkshopHits.push(...newItems);
                    this.trackOpenPickDate();
                    for (let c = 0; c < this.avaiableWorkshopHits.length; c++) {
                        if (this.avaiableWorkshopHits[c].dates.length) {
                            this.hasDates = true;
                            break;
                        }
                    }
                }
            },
            workshopHitsStatus(status) {
                if (status == states.Request)
                    this.loading = true;
                else
                    this.loading = false;
            },
            stepVisible(visible) {
                this.selectedDateFlag = false;
                if (visible)
                    setTimeout(function () { document.getElementById('workshop-query').focus() }.bind(this), 100);

                if (visible && this.preSelectedWorkhopServices != null && this.avaiableWorkshopHits.length == 0) {
                     this.ajaxUpdateWorkShop.post({
                         selectedServices: this.selectedServices,
                         includeDates: this.allwaysShowDates ? true : this.pricePossible,
                         workshopId: this.preSelectedWorkhopId
                     },
                         this.searchPreselecedSuccess(),
                         null
                     );
                }

            }
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
                pickWorkshopState: 'workshop/pickWorkshopState',
                reset: 'workshop/reset',
                pickWorkshopServices: 'price/pickWorkshopServices',
                pickServicePriceState: 'price/pickServicePriceState',
                resetPrice: 'price/resetPrice',
            }),
            closeCustomDatePicker() {
                this.pickerdate = null;
                this.pickertime = null;
                this.pickerSlots = [];
                this.showCustomDatePicker = false;
            },
            setDate(pickerdate, pickertime, pickerSlots) {
                this.pickerdate = pickerdate;
                this.pickertime = pickertime;
                this.pickerSlots = pickerSlots;
            },
            setTime(e) {
                if (e.target.selectedIndex == 0) {
                    this.pickertime = null;
                } else {
                    const time = e.target[e.target.selectedIndex].value;
                    this.pickertime = time;
                }
            },
            selectWorkshop(value) {
                this.selectedAvailableWorkshop = value.workshop.id;
                this.wsHasSelectableTimes = value.workshop.isTimeSelectable;
                if (this.timeSelectPossible && this.wsHasSelectableTimes) {
                    this.showCustomDatePicker = true;
                    this.trackOpenCalendar();
                }
            },
            gotoNextStep() {
                this.resetPrice();
                const dateObject = this.getDateAndTime();
                if (dateObject != null) {
                    if (this.includesPriceApi) {
                        let dataItems = [];
                        this.selectedServiceItems.forEach(s => {
                            const data = {
                                'age': this.pickCarData.age,
                                'distance': this.miles,
                                'regNo': this.pickCarData.regNo,
                                'selectedService': this.serviceType,
                                'selectedWorkshop': this.selectedWorkshopId,
                                'services': [s.Id],
                                'version': s.ServiceApiType,
                            }
                            if (s.HasPriceAPI)
                                dataItems.push(data);

                            if (!s.IsSimplifiedAPI && s.EnabledPriceApi)
                                this.selectedPriceApiVersion = s.ServiceApiType;
                          
                        });
                        this.ajaxServicePrice.post(dataItems);
                    }
                    if (this.includesStaticPrice) {
                        this.getWorkhopServices.post({ 'selectedWorkshop': this.selectedWorkshopId });
                    }
                    this.$store.commit('price/pickSelectedDate', dateObject);
                    this.$store.commit('price/isPriceRequest', true);
                    this.trackPickDate(dateObject.date);

                } else {
                    this.$store.commit('price/isPriceRequest', false);
                }

                if (this.timeSelectPossible && this.wsHasSelectableTimes && !this.showCustomDatePicker) {
                    this.showCustomDatePicker = true;
                    this.trackOpenCalendar();
                }
                else {
                    this.closeCustomDatePicker();
                    this.trackWorkshop();
                    this.switchStep(steps.userFormStep);
                }
            },
            getDateAndTime() {
                if (this.pickerdate != null)
                    return { date: this.pickerdate, time: this.pickertime }
                else {
                    const elDateQ = 'workshop-dates' + this.selectedAvailableWorkshop;
                    let elDate = document.getElementById(elDateQ);
                    if (elDate != null && elDate.length) {
                        const time = elDate.options[elDate.selectedIndex].getAttribute('data-open');
                        return { date: elDate.value, time: time };
                    }
                }
                return null;
            },
            gotoPrevStep() {
                this.switchStep(steps.servicesStep);
            },
            queryKeydown(event) {
                if (event.code == 'Enter')
                    this.tryStartSearch();
                else 
                    setTimeout(function () { this.tryStartSearch() }.bind(this), 2000)
            },
            tryStartSearch() {
                if (this.isNewQuery() && this.checkQueryValidy() && this.loading == false) {
                    if (this.geocoder == null)
                        this.geocoder = new window.google.maps.Geocoder();
                    this.queryprev = this.query;
                    this.doGeocode(this.query);
                }
            },
            doPos() {
                let app = this;
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        app.avaiableWorkshopHits = [];
                        app.page = 1;
                        app.lat = position.coords.latitude;
                        app.lng = position.coords.longitude;
                        app.posDone = true;
                        app.doSearch();
                    }, function () {
                    }
                );
            },
            doGeocode(query) {
                this.geocoder.geocode(
                    {
                        address: query,
                        componentRestrictions: { country: "SE" },
                    },
                    (results, status) => {
                        if (status == window.google.maps.GeocoderStatus.OK && results[0]) {
                            this.avaiableWorkshopHits = [];
                            this.page = 1;
                            this.lat = results[0].geometry.location.lat();
                            this.lng = results[0].geometry.location.lng();
                            this.posDone = false;
                            this.doSearch();
                        }
                    }
                );
            },
            doSearch() {
                this.ajaxUpdateWorkShops.post({
                        selectedServices: this.selectedServices,
                        lat: this.lat,
                        lng: this.lng,
                        forceOrderByDistance: !this.sortByDate,
                    includeDates: this.allwaysShowDates ? true : this.pricePossible,
                        page: this.page
                    },
                    this.searchSuccess(),
                    this.searchFailures()
                );
            },
            loadNextWorkshops() {
                this.page++;
                this.doSearch();
            },
            doSortByDate() {
                if (this.sortByDate)
                    return false;
                this.doSortBy(true);
            },
            doSortByDistance() {
                if (!this.sortByDate)
                    return false;
                this.doSortBy(false);
            },
            doSortBy(isDate) {
                this.sortByDate = isDate;
                this.page = 1;
                this.avaiableWorkshopHits = [];
                this.doSearch();
            },
            searchSuccess() {
            },
            searchFailures() {
            },
            searchPreselecedSuccess() {
                this.selectedAvailableWorkshop = new Number(this.preSelectedWorkhopId);
            },
            checkQueryValidy() {
                return document.getElementById('workshop-query').checkValidity();
            },
            isNewQuery() {
                return this.query != this.queryprev;
            },
            toDateString(date, open, option) {
                return localeDateString(date, open, option);
            },
            checkForDateModal(e, workshop) {
                this.selectedAvailableWorkshop = workshop.id;
                //const isLastIndex = (e.currentTarget.selectedIndex + 1) == e.currentTarget.options.length;
                const value = e.target[e.target.selectedIndex].value;
                if (!value.length) {
                    e.currentTarget.selectedIndex = 0;
                    this.showCustomDatePicker = true;
                    this.trackOpenCalendar();
                }
            },
            checkSelectedDateList(workshopId, date) {
                if (this.selectedAvailableWorkshop == workshopId && this.selectedDate != null && this.selectedDate == date) {
                    this.selectedDateFlag = true;
                    return true;
                }
                return false;
            },
            checkSelectedDate(workshopId) {
                if (this.selectedAvailableWorkshop == workshopId && this.selectedDate != null && this.selectedDateFlag == false) {
                    return true;
                }
                return false;
            },
            trackWorkshop() {

                let wsIndex = 1;
                this.avaiableWorkshopHits.forEach((w, index) => {
                    if (this.selectedWorkshopId == w.workshop.id)
                        wsIndex = index + 1;
                })

                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewPickWorkshopSuccess',
                        data: {
                            selectedWorkshop: this.selectedWorkshop,
                            searchWord: this.query,
                            selectedWorkshopIdx: wsIndex
                        },
                    });
            },
            trackPickDate(selectedTimeslot) {
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewPickDateSuccess',
                        data: { 'selectedTimeslot': selectedTimeslot },
                    });

            },
            trackOpenCalendar() {
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewOpenWorkshopCalendar',
                        data: {},
                    });
            },
            trackOpenPickDate() {

                let wsTimes = [];
                this.avaiableWorkshopHits.forEach(item => {
                    if (item.dates.length > 0) {
                        let wsTime = { workshopId: item.workshop.id, dates: [] }
                        for (let i = 0; i < item.dates.length; i++) {
                            if(i < 3)
                                wsTime.dates.push(item.dates[i].date);
                        }
                        wsTimes.push(wsTime);
                    }
                });

                if (wsTimes.length > 0) {
                    this.$store.commit('config/trackAction',
                        {
                            event: 'trackNewOpenPickDate',
                            data: {
                                'initiatedPickDate': 1,
                                'earliestDate': wsTimes
                            },
                        });
                    }
                }

        },
        async mounted() {
            this.ajaxUpdateWorkShops = ajaxHelper(
                '/api/applications/offers/getworkshopsdetailswithservice',
                this.pickWorkshopState,
                500,
                true,
                this.defaultTimeout
            );
            this.ajaxUpdateWorkShop = ajaxHelper(
                '/api/applications/offers/getworkshopdetailswithservice',
                this.pickWorkshopState,
                500,
                true,
                this.defaultTimeout
            );
            this.ajaxServicePrice = ajaxHelper('/api/applications/offers/v2/getServicePrices',
                this.pickServicePriceState,
                500,
                false,
                this.defaultTimeout);
            this.getWorkhopServices = ajaxHelper(
                '/api/applications/offers/v2/getServicesFromWorkshop',
                this.pickWorkshopServices,
                500,
                true,
                this.defaultTimeout
            );
        }
    }

</script>